@import url('https://fonts.googleapis.com/css?family=Luckiest+Guy');
* {
  box-sizing: border-box;
}
html, body{
  height: 100%;
  background: #f9faf4;
  font-family: 'Luckiest Guy', serif;

}
button{
  font-family: 'Luckiest Guy', serif;
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition: .3s;
  border-radius: .3em;
}
.answer{
  color: #f7e0fe;
  font-size: 3em;
  animation: colorchange 10s infinite alternate;
}
@keyframes colorchange{
  0%{
    color: #f7e0fe;
    margin-left: 0;
  }
  50%{
    color: #4CAF50;
    margin-left: 20px;
  }
  90%{
    color: pink;
    margin-right: 20px;
  }
  100%{
    color: #f7e0fe;
    margin-left: 0;
  }
}
button:hover{
  background-color: #f7e0fe;
  cursor: pointer;
}
button:focus{
 outline: none;
 border: none;
}
.App {
  color: #20bea6;
  text-align: center;
  text-shadow: #fff 0px 2px;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.choices{
  margin: auto;
  display: flex;
  width: 50%;
  justify-content: space-around;
  padding-top: 1em;
}
.footer {
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   transition: .3s;
   background-color: rgba(32, 190, 166, .6);
   color: white;
   text-align: center;
   padding: 2em;
}
.footer a{
  margin-left: 1em;
}
.twtr-icon{
  fill: #1ca1f2;
}
.twtr-icon:hover{
  fill: #fff;
}
